import { IS_DEV } from "../config/global";

export const getRedirectUrl = (id: string, isNewDev = false) => {
  if (IS_DEV) {
    if (isNewDev) {
      return `https://newdev.rmcoco.com/shop/product/${id}/99`;
    } else {
      return `https://newstage.rmcoco.com/shop/product/${id}/99`;
    }
  } else {
    return `https://rmcoco.com/shop/product/${id}/99`;
  }
};
export const getRedirectUrlWithDefaultProduct = (
  id: string,
  color: string,
  isNewDev: boolean = false
) => {
  if (IS_DEV) {
    if (isNewDev) {
      return `https://newdev.rmcoco.com/shop/product/${id}/${color}`;
    } else {
      return `https://newstage.rmcoco.com/shop/product/${id}/${color}`;
    }
  } else {
    return `https://rmcoco.com/shop/product/${id}/${color}`;
  }
};

export const getRedirectProjectUrl = (
  id: string,
  productId: string,
  isNewDev = false
) => {
  if (IS_DEV) {
    if (isNewDev) {
      return `https://newdev.rmcoco.com/shop/projects?projectTabId=${id}&productId=${productId}`;
    } else {
      return `https://newstage.rmcoco.com/shop/projects?projectTabId=${id}&productId=${productId}`;
    }
  } else {
    return `https://rmcoco.com/shop/projects?projectTabId=${id}&productId=${productId}`;
  }
};
export const getRedirectProjectWithClientUrl = (
  id: string,
  productId: string,
  isNewDev: boolean = false
) => {
  if (IS_DEV) {
    if (isNewDev) {
      return `https://newdev.rmcoco.com/shop/projects?projectTabId=${id}&productId=${productId}`;
    } else {
      return `https://newstage.rmcoco.com/shop/projects?projectTabId=${id}&productId=${productId}`;
    }
  } else {
    return `https://rmcoco.com/shop/projects?projectTabId=${id}&productId=${productId}`;
  }
};

export default getRedirectUrl;
