import React, {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useSearchParams } from "react-router-dom";
import SplashLoader from "../../components/SplashLoader/SplashLoader";

import { useUpdateRecolorAssetsMutation } from "../../hooks/graphql/graphql";
import getRedirectUrl, {
  getRedirectProjectUrl,
  getRedirectProjectWithClientUrl,
} from "../../utils/getRedirectUrl";

export type RecolorLayerStatus =
  | "loading"
  | "done"
  | "failed";

export type RecolorLayerType = {
  assetId: string;
  sessionId: string;
  productId: string;
  designName: string;
  layers: string[];
  lockedLayers: boolean[];
  layersStatus: RecolorLayerStatus[];
  onStatusChange: (
    index: number,
    status: RecolorLayerStatus
  ) => void;
  initialColors: string[];
  currentColors: string[];
  onColorChange: (color: string) => void;
  currentSelectedLayers: number;
  onLayerSelect: (index: number) => void;
  recolorStatus: RecolorLayerStatus;
  onUndo: () => void;
  canUndo: boolean;
  onRedo: () => void;
  canRedo: boolean;
  onStartOver: () => void;
  canStartOver: boolean;
  minRepeats: number;
  maxRepeats: number;
  defaultRepeats: number;
  currentRepeats: number;
  onRepeatsChange: (repeats: number) => void;
  isPreviewMode: boolean;
  onPreviewModeChange: (isPreviewMode: boolean) => void;
  isNew: boolean;
  setRedirectConfig: (projectTabId: string) => void;
  redirectConfig: string;
};

const RecolorLayerContext =
  React.createContext<RecolorLayerType>({
    isNew: false,
    productId: "",
    assetId: "",
    sessionId: "",
    designName: "",
    currentColors: [],
    initialColors: [],
    layers: [],
    lockedLayers: [],
    layersStatus: [],
    onColorChange: () => {},
    onStatusChange: () => {
      console.log("not plugged");
    },
    onLayerSelect: () => {},
    currentSelectedLayers: 0,
    recolorStatus: "loading",
    onUndo: () => {},
    onRedo: () => {},
    canRedo: false,
    canUndo: false,
    canStartOver: false,
    onStartOver: () => {},
    minRepeats: 1,
    maxRepeats: 108,
    defaultRepeats: 1,
    currentRepeats: 15,
    onRepeatsChange: () => {},
    isPreviewMode: false,
    onPreviewModeChange: () => {},
    setRedirectConfig: () => {},
    redirectConfig: "none",
  });

export const RecolorLayerProvider: FC<{
  layers: string[];
  colors: string[];
  lockedLayers: boolean[];
  minRepeats: number;
  maxRepeats: number;
  defaultRepeats: number;

  repeats: number;
  productId: string;
  sessionId: string;
  assetId: string;
  designName: string;
  isNew: boolean;
}> = ({
  children,
  colors,
  layers,
  minRepeats,
  maxRepeats,
  defaultRepeats,
  repeats,
  productId,
  sessionId,
  assetId,
  designName,
  lockedLayers,
  isNew,
}) => {
  const [redirectConfig, setRedirectConfig] =
    useState("NaN");
  const [isPreviewMode, setPreviewMode] = useState(false);
  const [_colors, setColors] = useState(colors);
  const [currentRepeats, setCurrentRepeats] =
    useState<number>(repeats);
  const [_currentSelectedLayers, setCurrentSelectedLayers] =
    useState(lockedLayers.findIndex((t) => t === false));
  const [undoStack, setUndoStack] = useState<
    {
      color: string[];
      currentSelectedLayers: number;
      currentRepeats: number;
    }[]
  >([]);
  const [redoStack, setRedoStack] = useState<
    {
      color: string[];
      currentSelectedLayers: number;
      currentRepeats: number;
    }[]
  >([]);
  const resetRedoStack = useCallback(() => {
    setRedoStack([]);
  }, []);
  useEffect(() => {
    setCurrentRepeats(repeats);
  }, [repeats]);
  const addStateToUndoStack = useCallback(() => {
    resetRedoStack();
    setUndoStack((undoStack) => [
      ...undoStack,
      {
        color: _colors,
        currentSelectedLayers: _currentSelectedLayers,
        currentRepeats: currentRepeats,
      },
    ]);
  }, [
    _colors,
    _currentSelectedLayers,
    resetRedoStack,
    currentRepeats,
  ]);

  const [layersStatus, setLayerStatus] = useState<
    RecolorLayerStatus[]
  >(layers.map(() => "loading"));

  const [recolorStatus, setRecolorStatus] =
    useState<RecolorLayerStatus>("loading");

  const onLayerSelect = useCallback(
    (index: number) => {
      setCurrentSelectedLayers(index);

      addStateToUndoStack();
    },
    [addStateToUndoStack]
  );

  const onColorChange = useCallback(
    (color: string) => {
      if (!lockedLayers[_currentSelectedLayers]) {
        const newColors = [..._colors];
        newColors[_currentSelectedLayers] = color;
        setColors(newColors);
        addStateToUndoStack();
      }
    },
    [
      _colors,
      _currentSelectedLayers,
      addStateToUndoStack,
      lockedLayers,
    ]
  );

  const onUndo = useCallback(() => {
    if (undoStack.length) {
      const {
        color,
        currentSelectedLayers,
        currentRepeats,
      } = undoStack.pop()!;

      setColors(color);
      setCurrentSelectedLayers(currentSelectedLayers);
      setCurrentRepeats(currentRepeats);

      setUndoStack(undoStack);
      setRedoStack([
        ...redoStack,
        {
          color: _colors,
          currentSelectedLayers: _currentSelectedLayers,
          currentRepeats: currentRepeats,
        },
      ]);
    }
  }, [
    _colors,
    _currentSelectedLayers,
    redoStack,
    undoStack,
  ]);
  const onRedo = useCallback(() => {
    if (redoStack.length) {
      const {
        color,
        currentSelectedLayers,
        currentRepeats,
      } = redoStack.pop()!;
      // console.log(
      //   color,
      //   currentSelectedLayers,
      //   "redo",
      //   redoStack
      // );
      setColors(color);
      setCurrentSelectedLayers(currentSelectedLayers);
      setCurrentRepeats(currentRepeats);
      setRedoStack(redoStack);
      setUndoStack([
        ...undoStack,
        {
          color: _colors,
          currentSelectedLayers: _currentSelectedLayers,
          currentRepeats: currentRepeats,
        },
      ]);
    }
  }, [
    _colors,
    _currentSelectedLayers,
    redoStack,
    undoStack,
  ]);
  const onStartOver = useCallback(() => {
    setColors(colors);
    setCurrentSelectedLayers(0);
    setUndoStack([]);
    setRedoStack([]);
    setCurrentRepeats(defaultRepeats);
  }, [colors, defaultRepeats]);
  useEffect(() => {
    setColors(colors);
  }, [colors]);
  const onStatusChange = useCallback(
    (index: number, status: RecolorLayerStatus) => {
      const _layersStatus = [...layersStatus];
      _layersStatus[index] = status;
      setLayerStatus(_layersStatus);

      if (
        _layersStatus.filter((s) => s === "done").length ===
        _layersStatus.length
      ) {
        setRecolorStatus("done");
      }
    },
    [layersStatus]
  );
  const onRepeatsChange = useCallback(
    (repeats: number) => {
      setCurrentRepeats(repeats);
      addStateToUndoStack();
    },
    [addStateToUndoStack]
  );

  const changeRedirectConfig = useCallback(
    (config: string) => {
      console.log("setRedirectConfig", config);
      setRedirectConfig(config);
    },
    []
  );

  return (
    <RecolorLayerContext.Provider
      value={{
        isNew,
        designName,
        productId,
        assetId,
        sessionId,
        defaultRepeats,
        onRedo,
        onUndo,
        onStartOver,
        onStatusChange,
        lockedLayers: lockedLayers,
        canRedo: redoStack.length > 0,
        canUndo: undoStack.length > 0,
        canStartOver:
          undoStack.length > 0 || redoStack.length > 0,
        currentColors: _colors,
        initialColors: colors,
        layers,
        layersStatus,
        currentSelectedLayers: _currentSelectedLayers,
        onLayerSelect,
        onColorChange: onColorChange,
        recolorStatus: recolorStatus,
        minRepeats: minRepeats,
        maxRepeats: maxRepeats,
        currentRepeats: currentRepeats,
        onRepeatsChange,
        isPreviewMode,
        onPreviewModeChange: (isPreviewMode: boolean) =>
          setPreviewMode(isPreviewMode),
        redirectConfig,
        setRedirectConfig: changeRedirectConfig,
      }}
    >
      {children}
      {recolorStatus === "loading" ? (
        <SplashLoader text="Loading Details..." />
      ) : null}
    </RecolorLayerContext.Provider>
  );
};

export const useRecolor = () => {
  const recolor = useContext(RecolorLayerContext);
  return recolor;
};

export const useCurrentLayerSelector = (): [
  number,
  (id: number) => void
] => {
  const recolor = useRecolor();
  const { currentSelectedLayers, onLayerSelect } = recolor;

  return [currentSelectedLayers, onLayerSelect];
};

export const useLayerCurrentColor = (): [
  string,
  (color: string) => void,
  boolean
] => {
  const recolor = useRecolor();
  const {
    lockedLayers,
    currentColors,
    currentSelectedLayers,
    onColorChange,
  } = recolor;

  return [
    currentColors[currentSelectedLayers],
    onColorChange,
    lockedLayers[currentSelectedLayers] ? true : false,
  ];
};

export const useRecolorActions = () => {
  const recolor = useRecolor();
  const {
    onUndo,
    canUndo,
    onRedo,
    canRedo,
    canStartOver,
    onStartOver,
  } = recolor;
  return {
    onUndo,
    canUndo,
    onRedo,
    canRedo,
    canStartOver,
    onStartOver,
  };
};

export const useRecolorRepeats = () => {
  const recolor = useRecolor();
  const {
    currentRepeats,
    onRepeatsChange,
    minRepeats,
    maxRepeats,
  } = recolor;
  return {
    currentRepeats,
    onRepeatsChange,
    minRepeats,
    maxRepeats,
  };
};

export const useRecolorPreview = () => {
  const recolor = useRecolor();
  const { isPreviewMode, onPreviewModeChange } = recolor;
  return { isPreviewMode, onPreviewModeChange };
};

export const useSaveRecolorAssets = () => {
  const [searchParams] = useSearchParams();
  const recolor = useRecolor();

  const [doSave] = useUpdateRecolorAssetsMutation();
  const [isSaving, setSaving] = useState(false);

  const onSave = useCallback(
    (
      designName: string,
      redirectConfig: string = "not_found"
    ) => {
      if (isSaving) {
        console.log("is Saving already");
        return;
      }
      setSaving(true);
      // const isFromProject =
      //   searchParams.get("from") === "project";
      // const projectTabId =
      //   searchParams.get("projectTabId") || "";
      // console.log("configRedirect in save", redirectConfig);
      // const redirectUrl = isFromProject
      //   ? getRedirectProjectUrl(
      //       projectTabId,
      //       recolor.productId || ""
      //     )
      //   : getRedirectProjectWithClientUrl(
      //       redirectConfig,
      //       recolor.productId || ""
      //     );
      const isFromProject =
          searchParams.get("from") === "project";
      const projectTabId =
          searchParams.get("projectTabId") || "";
      const isNewDev = searchParams.get("env") === "newdev";
      console.log("configRedirect in save", redirectConfig);
      const redirectUrl = isFromProject
          ? getRedirectProjectUrl(
              projectTabId,
              recolor.productId || "",
              isNewDev
            )
          : getRedirectUrl(recolor.productId || "", isNewDev);

      console.log("Redirect url", redirectUrl);
      doSave({
        fetchPolicy: "network-only",
        variables: {
          data: {
            repeats: recolor.currentRepeats,
            new_colors: recolor.currentColors,
            design_name: designName,
          },
          where: {
            // id: recolor.assetId,
            session_id_asset_id_id: {
              id: recolor.assetId,
              session_id: recolor.sessionId,
              asset_id: recolor.productId,
            },
            // session_id_asset_id: {
            //   session_id: recolor.sessionId,
            //   asset_id: recolor.productId,
            // },
          },
        },
      })
        .then((res) => {
          (window as any).location = redirectUrl;
          // (window as any).location = isFromProject
          //   ? getRedirectProjectUrl(
          //       projectTabId,
          //       recolor.productId || ""
          //     )
          //   : getRedirectUrl(recolor.productId || "");
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
          setSaving(false);
        });
      // .finally(() => {});
    },
    [
      isSaving,
      doSave,
      recolor.currentRepeats,
      recolor.currentColors,
      recolor.assetId,
      recolor.sessionId,
      recolor.productId,
      searchParams,
    ]
  );

  return {
    isSaving,
    onSave,
    designName: recolor.designName,
    isNew: recolor.isNew,
  };
};
